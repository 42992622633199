<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <nav class="level">
          <div class="level-left">
            <div class="level-item">
              <div class="field vdatepicker control has-icons-right">
                <v-date-picker
                  class="calendar_input"
                  clearable
                  is-range
                  mode='range'
                  v-model='dateFilter'
                  :input-props='{ placeholder: "Fecha" }'
                  @input="getListByDate"
                />
                <span class="icon is-right">
                  <span class="material-icons text_primary_green">today</span>
                </span>
              </div>
            </div>
          </div>
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="field control has-icons-right">
                <input
                  class="input input_reset"
                  type="text"
                  placeholder="Buscador"
                  v-model="searchText"
                  @input="searchSelect"
                >
                <span class="icon is-right">
                  <span class="material-icons">search</span>
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div class="columns is-multiline">
          <div class="column is-12 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="selectionList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-12">
            <base-pagination
              :page="page"
              :pages="parseInt(pages)"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'SelectionGuideMainDetail',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue')
  },
  data () {
    return {
      loading: false,
      page: 1,
      pages: 1,
      dateFilter: {},
      searchText: '',
      columnsData: [
        {
          id: 'name',
          header: 'Nombre de la guía',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-guia-de-seleccion', params: { selectionGuideId: row.id } })
        },
        { id: 'description', header: 'Descripción' },
        { id: 'publicationDate', header: 'Fecha de publicación', accessor: (row) => moment(row.created_at).format('DD/MM/YYYY') },
        { id: 'status', name: 'status', header: 'Estatus' }
      ],
      selectionList: []
    }
  },
  methods: {
    ...mapActions(['getSelectionGuide']),
    async getSelectionList (page, search, date) {
      let startDate = ''
      let endDate = ''
      if (date) {
        startDate = moment(this.dateFilter.start).format('YYYY-MM-DD 00:00:00')
        endDate = moment(this.dateFilter.end).format('YYYY-MM-DD 23:59:59')
      }
      const { guides, total, totalPages } = await this.getSelectionGuide({ page: page, limit: DEFAULT_DISPLAY_SIZE, search: search, startDate: startDate, endDate: endDate })
      this.total = total
      this.pages = totalPages
      this.selectionList = guides
      this.loading = false
    },
    searchSelect ({ target }) {
      this.page = 1
      if (target.value.length >= 3 || target.value.length === 0) {
        this.loading = true
        this.searchText = target.value.length === 0 ? '' : target.value
        this.$router.push(
          { name: 'guias-de-seleccion', query: { page: this.page, search: target.value } }
        ).catch(() => {})
        this.getSelectionList(this.page, target.value, this.dateFilter)
      }
    },
    getListByDate () {
      this.loading = true
      this.getSelectionList(this.page, this.searchText, this.dateFilter)
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.$router.push({ name: 'guias-de-seleccion', query: { page: this.page, search: this.searchText } })
      this.getSelectionList(this.page, this.searchText, this.dateFilter)
    }
  },
  beforeMount () {
    this.loading = true
    const { page, search } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.searchText = search === '' ? this.searchText : search
    this.dateFilter = ''
    this.getSelectionList(this.page, this.searchText, this.dateFilter)
  }
}
</script>

<style lang="scss" scoped>
  .vdatepicker {
    width: 250px;
  }

  .level-item {
    justify-content: flex-start;
  }

  @media screen and (max-width: 768px) {
    .level-item > *, select {
      width: 100%;
    }
  }
</style>
